.heading-atom {
    font-family: Oswald, sans-serif;
    color: #254153;
    font-weight: normal;
    text-transform: uppercase;
    padding: 0;
}

.heading-atom--no-margin {
    margin: 0;
}

.diagram-selector {
    margin: 1rem;
}

.diagram-tabrow {
    display: flex;
    justify-content: flex-start;
    align-content: flex-start;
    margin-left: 1rem;
}

.diagram-tabrow__tab {
    margin-right: .5rem;
    background-color: slategray;
    color: white;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    padding: .5rem;
    display: inline-block;
}

.diagram-tabrow__tab:hover {
    background-color: rgb(161, 174, 187);
}

.diagram-tabrow__tab--selected {
    background-color: white;
    color: #000;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    border-top: solid 1px #000;
    border-left: solid 1px #000;
    border-right: solid 1px #000;
    position: relative;
    z-index: 1;
    top: 1px;
    box-shadow: 0px -3px 3px 0px #888888;
}

.diagram-tabrow__tab--selected:hover {
    background-color: white;
}

.diagram-tabrow__tablink {
    cursor: pointer;
}

.button {
    display: inline-block;
    border-radius: 5px;
    color: #FFFFFF;
    font-size: 2rem;
    background-color: #7898ac;
    font-family: Oswald, sans-serif;
    padding: 0 .75rem;
    text-transform: uppercase;
    text-align: center;
}

.button--call-to-action {
    background-color: green;
}

.button--small {
    font-size: 1rem;
    padding: .2rem .5rem;
}

@media screen and (max-width: 60rem) {
    .button {
        font-size: 1.5rem;
        padding: .5rem;
    }
}
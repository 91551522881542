.preview {
    height: auto;
    width: 100%;
    background-color: #7898ac;
}

.preview-wrapper {
    height: 100%;
    margin: auto auto auto auto;
    /*padding: 3rem 2.5%;*/
    display: grid;
    grid-template-columns: 5fr 2fr 5fr;
    grid-template-rows: 1fr;
    grid-gap: 0.5rem;
}

.preview-left {
    height: 70%;
    display: flex;
    align-content: center;
    align-items: stretch;
    flex-direction: row;
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.preview-image-wrapper{
    display: flex;
    align-content: center;
    width: 100%;
    border-radius: 5px;
    background-color: white;
}

.preview-left__image {
    width: 100%;
}

.preview-middle {
    display:flex;
    align-content: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    font-size:3rem;
    padding: 0.5rem;
    font-family: Oswald, sans-serif;
}

.coras-title{
    font-size: 4rem;
    text-decoration: underline;
}

.coras-subtitle{
    font-size: 3rem;
    font-style: italic;
}

.preview-middle-button {
    align-self: center;
    margin-top: auto;
    margin-bottom: 0.5rem;
}
.preview-right {
    height: 70%;
    display: flex;
    align-content: center;
    align-items: stretch;
    flex-direction: row;
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.preview-right__image {
    width: 100%;
    flex-shrink: 0;
}

@media screen and (max-width: 60rem) {
    .preview-wrapper {
        width: auto;
        margin: 0;
        padding: 1rem 2rem;
        flex-wrap: wrap;
    }
    
    .preview-left {
        flex: 2 1 100%;
        padding: 0;
    }
    
    .preview-middle {
        flex: 2 1 100%;
        padding: 1rem 0 0 0;
        font-size: 2rem;
        text-align: center;
    }
}
.modal {
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, .5);
    z-index: 10000000;
    text-align: center;
}

.modal--no-background {
    background-color: initial;
}

.modal__content {
    width: auto;
    height: auto;
    display: inline-block;
    text-align: left;
    margin-top: 25%;
    background-color: #FFF;
    border: solid 1px #000;
    padding: 1rem;
}

.editor-tools {
    margin: 1rem;
}

.editor-tabrow {
    display: flex;
    justify-content: flex-start;
    align-content: flex-start;
}

.editor-tabrow__tablink {
    cursor: pointer;
 }

.editor-tabrow__tablink_toggle {
    margin-left: auto;
    cursor: pointer;
 }

.editor-tabrow__tab {
    margin-right: .5rem;
    background-color: slategray;
    color: white;
    padding: .5rem;
    display: inline-block;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
}

.editor-tabrow__tab_toggle {
    background-color: slategray;
    color: white;
    padding: .5rem;
    display: inline-block;
    position: relative;
    top: 1px;
}

.editor-tabrow__tab:hover {
    background-color: rgb(161, 174, 187);
}

.editor-tabrow__tab--selected {
    background-color: white;
    color: #000;
    border-top: solid 1px #000;
    border-left: solid 1px #000;
    border-right: solid 1px #000;
    position: relative;
    top: 1px;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    box-shadow: 0px -3px 3px 0px #888888;
}

.editor-tabrow__tab_toggle--selected {
    background-color: rgb(0, 139, 139);
    color: #000;

}

.editor-tabrow__tab--selected:hover {
    background-color: white;
}

.editor-toolbox {
    background-color: #FFF;
    border: solid 1px #000;
    display: flex;
    flex-wrap: wrap;
}

.editor-toolbox__element {
    flex: 1 1;
    padding: 1rem;
    align-content: center;
    text-align: center;
}

.editor-menu {
    margin: 1rem;
    display: flex;
    flex-wrap: wrap;
}

.editor-menu__button {
    margin-right: 1rem;
    background-color: darkcyan;
    border: solid 1px #FFF;
    padding: .5rem;
    color: white;
}

.editor-menu__hidden {
    display: none;
}

.editor-wrapper {
    height: 98.5vh;
}
.editor-paper {
    border: solid 1px #000;
    height: 80%;
    margin-bottom: 1rem;
    margin-left: 1rem;
    margin-right: 1rem;
    position: relative;
}

#paper-holder {
    position: absolute;
    top: 0;
    left: 0;
    margin: 1px;
}

.editor-clear-modal {

}

.editor-clear-modal__description {
    margin-bottom: 1rem;
}

.editor-clear-modal__button {
    background-color: lightslategray;
    border: solid 1px #000;
    border-radius: 2px;
    color: #FFF;
    margin-right: 1rem;
    padding: .4rem;
}

.editor-clear-modal__button--danger {
    background-color: darkred;
}

.key-points {
    display: flex;
    flex-wrap: wrap;
    margin: 0.5rem 0 0 0; 
}

.key-point {
    flex: 3 1;
    padding: 0.5rem 4rem;
}

.key-point__header {
    font-family: Oswald, sans-serif;
    color: #7898ac;
    font-size: 1.5rem;
    text-transform: uppercase;
}